import React, { useContext, useEffect, useState } from "react";
import Container from "@src/components/Container";
import SEO from "@src/components/SEO";
import { Button, BodyText, Space, theme, Title } from "@src/components/nessie-web";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import Translate from "@src/components/translation/Translate";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { mediaQueries } from "@src/styles/theme";
import isMobile from "@src/utils/isMobile";
import { logEvent } from "@src/utils/logClient";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const {
  colors: { dt_white },
} = theme;

const DownloadSection = styled.section`
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: ${dt_white};
  padding: 40px 0;

  ${mediaQueries[0]} {
    padding: 80px 0;
  }
`;

const DownloadFlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueries[0]} {
    flex-direction: row;
    align-items: center;
  }
`;

const DownloadFlexChild = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${mediaQueries[0]} {
    width: 50%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQueries[0]} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const HeroImage = styled.img`
  max-width: 100%;
`;

const Download: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_download {
          ios_button_url
          ios_icon {
            id
            filename_download
            filename_disk
          }
          android_button_url
          android_icon {
            id
            filename_download
            filename_disk
          }
          hero_image {
            id
            filename_disk
          }
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const isApple = isMobile().apple.device;
  const isAndroid = isMobile().android.device;

  const t = useContext(TranslationContext);
  const [showTeacherCopy, setShowTeacherCopy] = useState(false);
  const {
    directus: {
      page_download: { ios_icon, ios_button_url, android_icon, android_button_url, hero_image },
    },
  } = data;

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.download.page_view",
      eventValue: window.location.href,
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isTeacher = urlParams.get("type") === "teacher";
    if (isTeacher) {
      setShowTeacherCopy(true);
    }
  }, []);

  const logClickAndroid = () => {
    logEvent({
      eventName: "web.external_page.download.android.tap",
      eventValue: window.location.href,
    });
  };
  const logClickiOS = () => {
    logEvent({
      eventName: "web.external_page.download.ios.tap",
      eventValue: window.location.href,
    });
  };

  return (
    <>
      <SEO
        title="page_titles.download"
        description="page_descriptions.download"
        image="https://static.classdojo.com/img/logo/icon/classdojo_icon.png"
      />
      <DownloadSection>
        <Container>
          <DownloadFlexContainer>
            <DownloadFlexChild>
              <Title size={2}>
                {showTeacherCopy ? (
                  <Translate path="pages.download_app_teacher.title" />
                ) : (
                  <Translate path="directus.page_download.hero_title" />
                )}
              </Title>
              <Space size="xl" />
              <BodyText size={2}>
                {showTeacherCopy ? (
                  <Translate path="pages.download_app_teacher.subtitle" />
                ) : (
                  <Translate path="directus.page_download.hero_text" />
                )}
              </BodyText>
              <Space size="xl" />
              {isApple ? (
                <Button width="fit-content" href={ios_button_url} target="_blank" onClick={logClickiOS}>
                  <Translate path="directus.page_download.ios_button_text" />{" "}
                  <img
                    src={ios_icon.file.publicURL}
                    alt={t.translate("directus.page_download.ios_icon_alt") as string}
                  />
                </Button>
              ) : isAndroid ? (
                <Button width="fit-content" href={android_button_url} target="_blank" onClick={logClickAndroid}>
                  <Translate path="directus.page_download.android_button_text" />{" "}
                  <img
                    src={android_icon.file.publicURL}
                    alt={t.translate("directus.page_download.android_icon_alt") as string}
                  />
                </Button>
              ) : (
                <ButtonsWrapper>
                  <Button href={ios_button_url} target="_blank" onClick={logClickiOS}>
                    <Translate path="directus.page_download.ios_button_text" />{" "}
                    <img
                      src={ios_icon.file.publicURL}
                      alt={t.translate("directus.page_download.ios_icon_alt") as string}
                    />
                  </Button>
                  <Space kind="inline" size="s" />
                  <Space size="s" />
                  <Button href={android_button_url} target="_blank" onClick={logClickAndroid}>
                    <Translate path="directus.page_download.android_button_text" />{" "}
                    <img
                      src={android_icon.file.publicURL}
                      alt={t.translate("directus.page_download.android_icon_alt") as string}
                    />
                  </Button>
                </ButtonsWrapper>
              )}
            </DownloadFlexChild>
            <Space size="l" />
            <Space kind="inline" size="l" />
            <DownloadFlexChild>
              <HeroImage src={hero_image.file.publicURL} />
            </DownloadFlexChild>
          </DownloadFlexContainer>
        </Container>
      </DownloadSection>
    </>
  );
};

export default Download;
